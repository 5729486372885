.actions {
  border: 1px solid white;
  padding: 2px 6px;
}

.mainDiv {
  background-color: #e5e5e5;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}

.mainDiv-padding {
  padding: 32px;
}

.titleActions {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 16px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.title {
  margin: 0px;
  white-space: nowrap;
}

.addButton {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.tableActions {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.editIcon {
  color: #1fbbd3;
}

.trashIcon {
  color: red;
}

.spinner {
  text-align: center;
}

.tooltipSubmitRow {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tooltipTitle {
  font-size: 16px;
  padding: 5px 0px;
  line-height: 22px;
}

.boldTooltipText {
  color: #1fbbd3;
}

.searchBar {
  position: absolute;
}

.titleButtonRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}

.div-loader {
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  display: flex;
  position: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.452);
}

.div-loader-msg {
  color: white;
  font-weight: 500;
}

.pagination {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.changesText {
  display: flex;
  flex-direction: row;
}

.table-main-div {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
  margin-top: 16px;
}

.table-div {
  border-radius: 4px;
  width: 100%;
  overflow-x: scroll;
}

.header {
  background-color: black;
  color: white;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.header-title-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.header-title {
  font-weight: normal;
  text-align: start;
  padding: 8px;
  border: none;
  white-space: nowrap;
  font-size: 14px;
}

.header-search {
  text-align: right;
  padding: 4px;
}

.filter {
  display: flex;
  flex-direction: row;
  margin-top: 4px;
  width: 100%;
}

.body-cell {
  white-space: nowrap;
  padding: 8px;
  font-size: 14px;
}

.search {
  font-weight: 500;
  padding: 8px;
  position: sticky;
  right: 0;
}

.search-input {
  font-size: 14px;
  border: 0;
  padding: 4px;
}

.table-pagination {
  padding: 8px;
  text-align: right;
  font-size: 14px;
}

.pagination-select {
  margin-right: 16px;
}

.sortIcon {
  color: #1fbbd3;
}

.sortIconNot {
  color: grey;
}

.sortIcon-div {
  padding-left: 8px;
}

.actions-cell {
  padding: 8px;
  position: sticky;
  right: 0;
  background-color: white;
  border-left: 1px solid rgba(224, 224, 224, 1);
}

.actions-header {
  position: sticky;
  right: 0;
  font-weight: normal;
  text-align: start;
  padding: 8px;
  border-left: 1px solid rgba(224, 224, 224, 1);
  white-space: nowrap;
  font-size: 14px;
  background-color: black;
}
